import Select from '@totallymoney/ui/components/Select'
// @ts-ignore
import Sort from '@totallymoney/ui/icons/Sort'
import theme from '@totallymoney/ui/theme'
import { ChangeEvent } from 'react'

import { StyledSorterWrapper } from './TableSorter.styled'

interface TableSorterProps<TSortKey extends string> {
    onSortChange: (sortKey: TSortKey) => void
    sortOptions: Record<TSortKey, string>
    value: TSortKey
}

const TableSorter = <TSortKey extends string>({
    value,
    sortOptions,
    onSortChange,
}: TableSorterProps<TSortKey>) => {
    const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        onSortChange(evt.target.value as TSortKey)
    }

    return (
        <StyledSorterWrapper>
            <Select
                icon={
                    <Sort
                        size={16}
                        color={theme.indigo100}
                        aria-label="Sort icon"
                        title="Sort icon"
                    />
                }
                name="sortBy"
                onChange={handleChange}
                value={value}
                data-testid="table-sorter"
                aria-label="Sort the cards"
            >
                {Object.keys(sortOptions).map((key: string) => (
                    <option key={key} value={key}>
                        Sort by: {sortOptions[key as TSortKey] as string}
                    </option>
                ))}
            </Select>
        </StyledSorterWrapper>
    )
}

export default TableSorter
