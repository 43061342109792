import Box from '@totallymoney/ui/components/Box'
import CategoryNavigation from '@totallymoney/ui/components/CategoryNavigation'
import Grid from '@totallymoney/ui/components/Grid'
import React from 'react'

import { getCategoryPathForId } from '@utils/getCategoryPathForId'

import {
    CreditCardProductCategory,
    ScoredCreditCardProductsCategory,
} from '@appTypes/EligibilityApi.interface'

import useAppQueryState from '@hooks/useAppQueryState'

import KbaStatusBanner from '@components/KbaStatusBanner'
import TableSorter from '@components/TableSorter'

import { hasKbaScenario } from '@config/kbaScenarioKeys'

import MinimumCreditLimitUpdate from './components/MinimumCreditLimitUpdate'

interface ScoredTableHeaderProps {
    activeCategory: CreditCardProductCategory
    categories: ScoredCreditCardProductsCategory[]
    onCategoryChange: (category: CreditCardProductCategory) => void
    onShowMinimumCreditLimitModal: () => void
    onSortChange: (sortKey: string) => void
    sortOptions: Record<string, string>
    sortValue: string
}

const ScoredTableHeader = ({
    activeCategory,
    categories,
    sortValue,
    sortOptions,
    onSortChange,
    onCategoryChange,
    onShowMinimumCreditLimitModal,
}: ScoredTableHeaderProps) => {
    const { appQueryState, goToRouteWithAppState, getRouteUrl } =
        useAppQueryState()

    return (
        <>
            <CategoryNavigation
                categories={categories.map(category => ({
                    displayName: `${category.displayName} (${category.totalCreditCards})`,
                    value: category.id,
                    url: getRouteUrl(getCategoryPathForId(category.id)),
                }))}
                initialActiveCategory={activeCategory}
                onCategoryChange={category => {
                    goToRouteWithAppState(
                        getCategoryPathForId(
                            category.value as CreditCardProductCategory
                        ),
                        { shallow: true }
                    )

                    onCategoryChange?.(
                        category.value as CreditCardProductCategory
                    )
                }}
            />
            {hasKbaScenario(appQueryState.kbaScenario) ? (
                <KbaStatusBanner kbaScenario={appQueryState.kbaScenario} />
            ) : null}
            <Grid>
                <Box gridColumn={['1/13', '1/9']} mt={['space20', 'space30']}>
                    <MinimumCreditLimitUpdate
                        onShowMinimumCreditLimitModal={
                            onShowMinimumCreditLimitModal
                        }
                    />
                </Box>
                <Box gridColumn={['1/13', '9/13']} mt={['space20', 'space30']}>
                    <TableSorter
                        value={sortValue}
                        sortOptions={sortOptions}
                        onSortChange={sortKey => {
                            onSortChange?.(sortKey)
                        }}
                    />
                </Box>
            </Grid>
        </>
    )
}

export default ScoredTableHeader
