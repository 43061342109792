import Box from '@totallymoney/ui/components/Box'
import Text from '@totallymoney/ui/components/Text'
import Icon from '@totallymoney/ui/icons/Icon'
import theme from '@totallymoney/ui/theme'
import { ReactElement } from 'react'

interface CardTableBulletListProps {
    balanceTransferComboLimit?: number
    balanceTransferPeriodMonths?: number
    guaranteedCreditLimit?: number
    items: string[]
}

const CardTableBulletList = ({
    guaranteedCreditLimit,
    balanceTransferComboLimit,
    balanceTransferPeriodMonths,
    items,
}: CardTableBulletListProps): ReactElement => {
    const bulletList = (() => {
        if (
            guaranteedCreditLimit &&
            balanceTransferComboLimit &&
            balanceTransferPeriodMonths
        ) {
            const combinedBulletsCopy = [
                `Guaranteed £${guaranteedCreditLimit} spending limit`,
                `Guaranteed £${balanceTransferComboLimit} balance transfer limit`,
                `0% on balance transfers for ${balanceTransferPeriodMonths} months`,
            ]
            return [...combinedBulletsCopy, ...items]
        }
        return items
    })()

    return (
        <Box as="ul">
            {bulletList?.map((item: string) => (
                <Box
                    key={item}
                    display="flex"
                    pt="space10"
                    pl="space30"
                    as="li"
                >
                    <Box pt="space05">
                        <Icon
                            icon="checkmark"
                            size={13}
                            color={theme.grey100}
                        />
                    </Box>
                    <Box pl="space10">
                        <Text color="light">{item}</Text>
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

export default CardTableBulletList
