import Box from '@totallymoney/ui/components/Box'
import theme from '@totallymoney/ui/theme'
import { ReactNode } from 'react'

const ScoredTableItemFooterWrapper = ({
    children,
}: {
    children: ReactNode
}) => (
    <Box
        gridColumn="1/13"
        pt="space10"
        borderTop={`solid 1px ${theme.greyLighter}`}
    >
        {children}
    </Box>
)

export default ScoredTableItemFooterWrapper
