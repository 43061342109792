import Badge from '@totallymoney/ui/components/Badge'
import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import Icon from '@totallymoney/ui/icons/Icon'
import theme from '@totallymoney/ui/theme'

import { getIsProductPortraitImage } from '@utils/getIsProductPortraitImage'

import {
    CreditCardProductCategory,
    OfferType,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'

import { getCategoryBadgeText } from '@routes/ScoredTableRoute/components/ScoredTable/components/ScoredTableItem/utils/getCategoryBadgeText'
import { getShowBullets } from '@routes/ScoredTableRoute/components/ScoredTable/utils/getShowBullets'

import BannerOffer from '@components/BannerOffer'
import BannerStack from '@components/BannerStack'
import CardTableBulletList from '@components/CardTableBulletList'
import CardTableContentBase from '@components/CardTableContentBase'
import CardTableImage from '@components/CardTableImage'
import CardTableItemProductDetail from '@components/CardTableItemProductDetail'
import CreditCardContent from '@components/CreditCardContent'
import RepresentativeExample from '@components/RepresentativeExample'
import TmExclusiveIcon from '@components/TmExclusiveIcon/TmExclusiveIcon'

import * as Layout from './components/UnscoredTableItemLayout'

interface UnscoredTableItemProps {
    category: CreditCardProductCategory
    item: ScoredCreditCardProduct
    onShowRepresentativeExampleModal: () => void
    renderButtons: (item: ScoredCreditCardProduct) => React.ReactNode
}

const UnscoredTableItem = ({
    category,
    item,
    renderButtons,
    onShowRepresentativeExampleModal,
}: UnscoredTableItemProps) => {
    const showBullets = getShowBullets(category)

    const categoryBadgeText =
        item.productCategories && category
            ? getCategoryBadgeText(item.productCategories, category)
            : ''

    const isExclusiveOffer = item.offerType === OfferType.TMExclusive
    const isMarketWideOffer = item.offerType === OfferType.MarketWide

    return (
        <>
            {isExclusiveOffer ? (
                <BannerStack>
                    <BannerOffer
                        prefix={<TmExclusiveIcon />}
                        title={item.offerHeading}
                        description={item.offerCopy}
                        backgroundColor={theme.indigo30}
                        variant="backdrop"
                    />
                </BannerStack>
            ) : null}
            {isMarketWideOffer ? (
                <BannerStack>
                    <BannerOffer
                        title={item.offerHeading}
                        description={item.offerCopy}
                        backgroundColor={theme.infoLightBlue}
                        variant="backdrop"
                    />
                </BannerStack>
            ) : null}
            <Layout.ItemBase productCode={item.productCode}>
                <Layout.ItemWrapper>
                    <Layout.ItemHeading>
                        <Heading as="h2" variant="60">
                            {item.name}
                        </Heading>
                    </Layout.ItemHeading>

                    <Layout.ItemImage>
                        <CardTableImage
                            cmsImageUrl={item.imageUrlLarge}
                            name={item.name}
                            isPortraitImage={getIsProductPortraitImage(
                                item.productCode
                            )}
                        />
                    </Layout.ItemImage>

                    <Layout.ItemContent showBullets={showBullets}>
                        {categoryBadgeText ? (
                            <Box mb="space20">
                                <Badge
                                    text={categoryBadgeText}
                                    size="medium"
                                    icon="checkmark"
                                />
                            </Box>
                        ) : null}

                        <CardTableContentBase>
                            <CreditCardContent
                                category={category}
                                creditCard={item}
                                renderProductDetail={details => {
                                    return (
                                        <CardTableItemProductDetail
                                            {...details}
                                        />
                                    )
                                }}
                            />
                        </CardTableContentBase>
                    </Layout.ItemContent>

                    {showBullets ? (
                        <Box
                            gridRow={[
                                '4',
                                showBullets ? '4/6 ' : '4',
                                showBullets ? '2/4' : '2',
                            ]}
                            gridColumn={['span 12', '6/13', '7/10']}
                            mb={['space30', 0, 0]}
                        >
                            <CardTableBulletList
                                items={item.bulletsPositive}
                                balanceTransferComboLimit={
                                    item.balanceTransferComboLimit
                                }
                                balanceTransferPeriodMonths={
                                    item.balanceTransferOfferDurationMonths
                                }
                                guaranteedCreditLimit={
                                    item.guaranteedRate.guaranteedCreditLimit
                                }
                            />
                        </Box>
                    ) : null}

                    <Layout.ItemButtons>
                        {renderButtons(item)}
                    </Layout.ItemButtons>
                </Layout.ItemWrapper>

                <Layout.ItemFooter>
                    <RepresentativeExample
                        item={item}
                        onTooltipPress={onShowRepresentativeExampleModal}
                    />
                </Layout.ItemFooter>
            </Layout.ItemBase>
        </>
    )
}

export default UnscoredTableItem
