import Image from 'next/image'

import Box from '@totallymoney/ui/components/Box'
import Stack from '@totallymoney/ui/components/Stack'
import Text from '@totallymoney/ui/components/Text'
import Icon from '@totallymoney/ui/icons/Icon'
import theme from '@totallymoney/ui/theme'
import pluralize from 'pluralize'

import { StyledButton } from './TotallySureTooltipBanner.styled'

import totallySureLogo from '../../../../../../public/images/TotallySure-logo.png'

const TotallySureTooltipBanner = ({
    numberOfTotallySureCardOffers,
    onClick,
}: {
    numberOfTotallySureCardOffers?: number
    onClick: () => void
}) => {
    if (!numberOfTotallySureCardOffers) {
        return null
    }

    return (
        <StyledButton
            aria-label="Opens a modal that explains what TotallySure offers means"
            onClick={onClick}
        >
            <Stack
                direction="row"
                space="space10"
                bg="eligibilityLevel2-bg30"
                py="space05"
                pl="space10"
                pr="space15"
                borderRadius="borderRadius20"
                alignItems="center"
                justifyContent={[
                    'space-between',
                    'space-between',
                    'flex-start',
                ]}
            >
                <Stack direction="row" space="space10" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Image
                            alt="TotallySure logo"
                            src={totallySureLogo}
                            width={90}
                            height={40}
                        />
                    </Box>

                    <Text variant="80" color="light" textAlign="left">
                        <strong>
                            You have {numberOfTotallySureCardOffers} TotallySure{' '}
                            {pluralize(
                                'offer',
                                numberOfTotallySureCardOffers,
                                false
                            )}
                        </strong>{' '}
                        <Box as="span" display={['none', 'none', 'inline']}>
                            with pre-approval and guarantees (subject to final
                            checks)
                        </Box>
                    </Text>
                </Stack>

                <Icon icon="tooltip" size={20} color={theme.grey100} />
            </Stack>
        </StyledButton>
    )
}

export default TotallySureTooltipBanner
