import Badge from '@totallymoney/ui/components/Badge'
import Box from '@totallymoney/ui/components/Box'
import Heading from '@totallymoney/ui/components/Heading'
import Stack from '@totallymoney/ui/components/Stack'
import theme from '@totallymoney/ui/theme'

import { getCardApr } from '@utils/getCardApr'
import getGuaranteedOfferType from '@utils/getGuaranteedOfferType'
import { getIsProductPortraitImage } from '@utils/getIsProductPortraitImage'

import {
    CreditCardProductCategory,
    GuaranteedOfferType,
    OfferType,
    ScoredCreditCardProduct,
} from '@appTypes/EligibilityApi.interface'
import { GetGuaranteedCreditLimitResponse } from '@appTypes/PagesApi.interface'

import BannerOffer from '@components/BannerOffer'
import BannerStack from '@components/BannerStack'
import CardTableBulletList from '@components/CardTableBulletList'
import CardTableContentBase from '@components/CardTableContentBase'
import CardTableItemProductDetail from '@components/CardTableItemProductDetail'
import CreditCardContent from '@components/CreditCardContent'
import EligibilityIndicator from '@components/EligibilityIndicator'
import { getHasOfferTypeContent } from '@components/GuaranteedOfferModal/GuaranteedOfferModal'
import GuaranteedOfferQuestion from '@components/GuaranteedOfferQuestion'
import NewOfferBadge from '@components/NewOfferBadge'
import PreApprovedIndicator from '@components/PreApprovedIndicator'
import RepresentativeExample from '@components/RepresentativeExample'
import TmExclusiveIcon from '@components/TmExclusiveIcon'

import { getShowBullets } from '../../utils/getShowBullets'
import BestMatchBadge from './components/BestMatchBadge'
import ScoredTableItemImage from './components/ScoredTableItemImage'
import {
    ScoredTableItemButtonWrapper,
    ScoredTableItemCard,
    ScoredTableItemDetailsWrapper,
    ScoredTableItemFooterWrapper,
    ScoredTableItemHeaderWrapper,
    ScoredTableItemIllustrativeStatsWrapper,
} from './components/ScoredTableItemLayout'
import TotallySureCornerBanner from './components/TotallySureCornerBanner'

import { getCategoryBadgeText } from './utils/getCategoryBadgeText'

export interface ScoredTableItemProps {
    category: CreditCardProductCategory
    isBestMatch: boolean
    isNewOffer: boolean
    item: ScoredCreditCardProduct
    minCreditLimitData?: GetGuaranteedCreditLimitResponse | null
    onShowBestMatchModal: () => void
    onShowCreditLimitModal?: () => void
    onShowGuaranteedOfferModal: () => void
    onShowMinimumCreditLimitModal?: () => void
    onShowPreApprovedModal: () => void
    onShowRepresentativeExampleModal: () => void
    renderButtons: (item: ScoredCreditCardProduct) => React.ReactNode
}

const ScoredTableItem = ({
    category,
    item,
    isBestMatch = false,
    isNewOffer = false,
    renderButtons,
    onShowBestMatchModal,
    onShowPreApprovedModal,
    onShowRepresentativeExampleModal,
    onShowGuaranteedOfferModal,
    onShowCreditLimitModal,
    onShowMinimumCreditLimitModal,
    minCreditLimitData,
}: ScoredTableItemProps) => {
    const isNewOfferOrBestMatch = isBestMatch || isNewOffer

    const showBullets = getShowBullets(category)

    const categoryBadgeText =
        item.productCategories && category
            ? getCategoryBadgeText(item.productCategories, category)
            : ''

    const isExclusiveOffer = item.offerType === OfferType.TMExclusive
    const isMarketWideOffer = item.offerType === OfferType.MarketWide
    const isPreApproved = item.isPreApproved

    const canShowEligibilityMeter =
        !isPreApproved && item.score > 0 && item.score < 10

    const guaranteedOfferType = getGuaranteedOfferType({
        creditCard: item,
    })

    const canShowGuaranteedOfferTooltip =
        guaranteedOfferType !== GuaranteedOfferType.MarketingOffer &&
        getHasOfferTypeContent(guaranteedOfferType)

    const isMarketingOffer =
        guaranteedOfferType === GuaranteedOfferType.MarketingOffer

    const isCreditLimitGuaranteed = [
        GuaranteedOfferType.GuaranteedCreditLimit,
        GuaranteedOfferType.GuaranteedAprAndCreditLimit,
        GuaranteedOfferType.PreApprovedAndGuaranteedCreditLimit,
    ].includes(guaranteedOfferType!)

    return (
        <>
            {isExclusiveOffer ? (
                <BannerStack>
                    <BannerOffer
                        prefix={<TmExclusiveIcon />}
                        title={item.offerHeading}
                        description={item.offerCopy}
                        backgroundColor={theme.indigo30}
                        variant="backdrop"
                    />
                </BannerStack>
            ) : null}

            {isMarketWideOffer ? (
                <BannerStack>
                    <BannerOffer
                        title={item.offerHeading}
                        description={item.offerCopy}
                        backgroundColor={theme.infoLightBlue}
                        variant="backdrop"
                    />
                </BannerStack>
            ) : null}

            {isCreditLimitGuaranteed ? (
                <BannerStack>
                    <BannerOffer
                        title="Credit limit guaranteed"
                        description=""
                        backgroundColor={theme.eligibilityLevel0}
                        variant="gcl"
                    />
                </BannerStack>
            ) : null}

            <ScoredTableItemCard
                productCode={item.productCode}
                isNewOfferOrBestMatch={isNewOfferOrBestMatch}
                isMarketingOffer={isMarketingOffer}
            >
                {isMarketingOffer ? <TotallySureCornerBanner /> : null}

                <Stack
                    space="space30"
                    display={['flex', 'grid']}
                    mb="space30"
                    position="relative"
                    zIndex={1}
                >
                    <ScoredTableItemHeaderWrapper
                        canShowEligibilityMeter={canShowEligibilityMeter}
                    >
                        {isNewOfferOrBestMatch ? (
                            <Stack
                                direction="row"
                                space="space10"
                                display={['flex', 'none']}
                                gridRow={0}
                                gridColumn="1/13"
                                mb="space30"
                            >
                                {isBestMatch ? (
                                    <Box>
                                        <BestMatchBadge
                                            onClick={onShowBestMatchModal}
                                        />
                                    </Box>
                                ) : null}

                                {isNewOffer ? (
                                    <Box>
                                        <NewOfferBadge />
                                    </Box>
                                ) : null}
                            </Stack>
                        ) : null}

                        <Stack direction="row" space="space20">
                            <Box justifySelf={['center', 'unset']}>
                                <ScoredTableItemImage
                                    cmsImageUrl={item.imageUrlLarge}
                                    name={item.name}
                                    isPortraitImage={getIsProductPortraitImage(
                                        item.productCode
                                    )}
                                />
                            </Box>

                            <Stack
                                space="space10"
                                pr={[0, isMarketingOffer ? 'space100' : 0]}
                            >
                                {isNewOfferOrBestMatch ? (
                                    <Stack
                                        direction="row"
                                        space="space10"
                                        display={['none', 'flex']}
                                    >
                                        {isBestMatch ? (
                                            <Box>
                                                <BestMatchBadge
                                                    onClick={
                                                        onShowBestMatchModal
                                                    }
                                                />
                                            </Box>
                                        ) : null}

                                        {isNewOffer ? (
                                            <Box>
                                                <NewOfferBadge />
                                            </Box>
                                        ) : null}
                                    </Stack>
                                ) : null}

                                <Heading as="h2" variant="60">
                                    {item.name}
                                </Heading>

                                {categoryBadgeText ? (
                                    <Badge
                                        text={categoryBadgeText}
                                        size="medium"
                                        icon="checkmark"
                                    />
                                ) : null}
                            </Stack>
                        </Stack>
                    </ScoredTableItemHeaderWrapper>

                    <ScoredTableItemIllustrativeStatsWrapper
                        canShowEligibilityMeter={canShowEligibilityMeter}
                    >
                        {canShowEligibilityMeter ? (
                            <Box
                                width={['100%', '208px']}
                                display="flex"
                                justifyContent="flex-start"
                                alignSelf="center"
                            >
                                <EligibilityIndicator score={item.score * 10} />
                            </Box>
                        ) : null}

                        {isPreApproved ? (
                            <Box
                                width={['100%', '208px']}
                                display="flex"
                                justifyContent="center"
                                alignSelf="center"
                            >
                                <PreApprovedIndicator
                                    id={item.productCode}
                                    apr={getCardApr(item)}
                                    onPress={onShowPreApprovedModal}
                                />
                            </Box>
                        ) : null}

                        <Box display={['none', 'none', 'block']}>
                            {renderButtons(item)}
                        </Box>
                    </ScoredTableItemIllustrativeStatsWrapper>

                    <ScoredTableItemDetailsWrapper
                        canShowEligibilityMeter={canShowEligibilityMeter}
                    >
                        <Box
                            display={['block', 'flex']}
                            justifyContent={['unset', 'flex-start']}
                            flexDirection={['column', 'row']}
                        >
                            <CardTableContentBase
                                hasGuaranteedBadge={
                                    isMarketingOffer || isCreditLimitGuaranteed
                                }
                            >
                                <CreditCardContent
                                    category={category}
                                    creditCard={item}
                                    onShowCreditLimitModal={
                                        onShowCreditLimitModal
                                    }
                                    onShowMinimumCreditLimitModal={
                                        onShowMinimumCreditLimitModal
                                    }
                                    minCreditLimitData={minCreditLimitData}
                                    renderProductDetail={details => {
                                        if (details.value === 'N/A') {
                                            return <></>
                                        }
                                        return (
                                            <CardTableItemProductDetail
                                                {...details}
                                            />
                                        )
                                    }}
                                />
                            </CardTableContentBase>

                            {showBullets ? (
                                <Box mb={['space30', 0, 0]} ml={[0, 'auto']}>
                                    <CardTableBulletList
                                        items={
                                            item.bulletsPositive?.slice(0, 4) ??
                                            []
                                        }
                                        balanceTransferComboLimit={
                                            item.balanceTransferComboLimit
                                        }
                                        balanceTransferPeriodMonths={
                                            item.balanceTransferOfferDurationMonths
                                        }
                                        guaranteedCreditLimit={
                                            item.guaranteedRate
                                                .guaranteedCreditLimit
                                        }
                                    />
                                </Box>
                            ) : null}
                        </Box>
                    </ScoredTableItemDetailsWrapper>

                    {canShowGuaranteedOfferTooltip ? (
                        <Box
                            gridColumn={['1/13', '1/13', '1/10']}
                            gridRow={[4, canShowEligibilityMeter ? 3 : 4, 3]}
                        >
                            <GuaranteedOfferQuestion
                                onTooltipPress={() =>
                                    onShowGuaranteedOfferModal()
                                }
                            />
                        </Box>
                    ) : null}

                    <ScoredTableItemButtonWrapper
                        canShowEligibilityMeter={canShowEligibilityMeter}
                        canShowGuaranteedOfferTooltip={
                            canShowGuaranteedOfferTooltip
                        }
                    >
                        {renderButtons(item)}
                    </ScoredTableItemButtonWrapper>
                </Stack>

                <ScoredTableItemFooterWrapper>
                    <RepresentativeExample
                        item={item}
                        onTooltipPress={onShowRepresentativeExampleModal}
                    />
                </ScoredTableItemFooterWrapper>
            </ScoredTableItemCard>
        </>
    )
}

export default ScoredTableItem
