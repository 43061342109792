import Stack from '@totallymoney/ui/components/Stack'
import { ReactNode } from 'react'

const ScoredTableItemIllustrativeStatsWrapper = ({
    canShowEligibilityMeter,
    children,
}: {
    canShowEligibilityMeter: boolean
    children: ReactNode
}) => (
    <Stack
        space="space30"
        gridRow={[2, canShowEligibilityMeter ? 1 : 2, 'span 2']}
        gridColumn={[
            '1/13',
            canShowEligibilityMeter ? '9/13' : '1/13',
            'span 1',
        ]}
        display="flex"
        justifyContent={[
            'unset',
            canShowEligibilityMeter ? 'flex-end' : 'center',
            'flex-start',
        ]}
        flexDirection="column"
    >
        {children}
    </Stack>
)

export default ScoredTableItemIllustrativeStatsWrapper
