import Box from '@totallymoney/ui/components/Box'
import { ReactNode } from 'react'

const ScoredTableItemButtonWrapper = ({
    canShowEligibilityMeter,
    canShowGuaranteedOfferTooltip,
    children,
}: {
    canShowEligibilityMeter: boolean
    canShowGuaranteedOfferTooltip: boolean
    children: ReactNode
}) => (
    <Box
        display={['block', 'block', 'none']}
        gridColumn={['1/13', '1/13', '11/13']}
        gridRow={[
            canShowGuaranteedOfferTooltip ? 5 : 4,
            canShowGuaranteedOfferTooltip
                ? canShowEligibilityMeter
                    ? 4
                    : 3
                : 4,
            3,
        ]}
    >
        {children}
    </Box>
)

export default ScoredTableItemButtonWrapper
