import Badge from '@totallymoney/ui/components/Badge'
import { StyledButton } from './BestMatchBadge.styled'

const BestMatchBadge = ({ onClick }: { onClick: () => void }) => (
    <StyledButton onClick={onClick} aria-label="What is my Best Match?">
        <Badge impact="high" size="medium" text="Your Best Match" />
    </StyledButton>
)

export default BestMatchBadge
