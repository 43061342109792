import LinkButton, {
    LinkButtonProps,
} from '@totallymoney/ui/components/LinkButton/LinkButton'
import Link, { LinkProps } from 'next/link'
import React from 'react'

interface NextLinkButtonProps extends Omit<LinkButtonProps, 'href'> {
    shallow?: boolean
    style?: React.CSSProperties
    to: LinkProps['href']
}

const NextLinkButton = ({ to, shallow, ...props }: NextLinkButtonProps) => (
    <Link href={to} passHref={true} legacyBehavior={true} shallow={shallow}>
        {/* @ts-ignore */}
        <LinkButton {...props} />
    </Link>
)

export default NextLinkButton
